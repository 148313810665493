<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Footer from "@/layouts/Footer.vue";
//COMPONENTS
import Modal from "@/components/Modal.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		SecondaryButton,
		Footer,
		Modal,
	},
	data() {
		return {
			messages: [],
			modal: {
				title: null,
				message: null,
				comment: null,
				showComment: false,
				show: false,
				gone: false,
				reaction: null,
				type: '',
			},
		};
	},
	async created() {
		this.setLoading(false);
		await this.loadMessages();
		await this.getReactions();
	},
	computed: {
		...mapGetters({
			readNotifications: "getNotifications/readNotifications",
			//ERRORS
			readNotificationsError: "getNotifications/readError",
			readHasReadError: "hasReadNotification/readError",
			readReactions: "getReactions/readReactions",
		}),
	},
	methods: {
		...mapMutations({
			//LOADING
			setLoading: "loading/SET_LOADING",
			setNotificationsId: "getNotifications/SET_ID",
			setHasReadId: "hasReadNotification/SET_ID",
			//ERRORS
			setNotificationsError: "getNotifications/SET_ERROR",
			setHasReadError: "hasReadNotification/SET_ERROR",
		}),
		...mapActions({
			setHasRead: "hasReadNotification/hasRead",
			getNotifications: "getNotifications/getNotifications",
			getReactions: 'getReactions/getReactions'
		}),
		toggleModal() {
			this.modal.show = !this.modal.show;
		},
		getImage(image) {
			return `${require(`@/assets/images/views/VOTACION/INICIO/${image}`)}`;
		},
		selectMessage(key) {
			if (this.messages[key]?.is_unread) {
				this.modal.title = this.messages[key]?.title;
				this.modal.message = this.messages[key]?.content;
				this.modal.showComment= false
				this.modal.comment = this.messages[key]?.comment;
				this.modal.type = this.messages[key]?.type
				this.modal.reaction = this.readReactions.find(r => r.id == this.messages[key].reaction_id)
				this.toggleModal();
				this.markRead(key)
			}
		},
		markRead(key) {
			this.cleanErrors();
			this.setHasReadId(this.messages[key]?.id);
			this.setHasRead();
		},
		loadMessages() {
			this.setLoading(true);
			this.cleanErrors();
			this.setNotificationsId(null);
			this.getNotifications().then((result) => {
				if (result) {
					this.messages = this.readNotifications;
					let index = this.readNotifications.findIndex(n => n.is_unread)
					this.selectMessage(index);
				} else {
					this.messages = [];
				}
			});
			this.setLoading(false);
		},
		begin() {
			return !this.isTour ? this.$router.push({ name: "Reconocer" }) : null;
		},
		getReactionImage(image) {
			return `${require(`@/assets/images/components/reactions/${image}`)}`;
		},
		cleanErrors() {
			this.setHasReadError(null);
			this.setNotificationsError(null);
		},
		hideModal() {
			this.modal.gone = true;
			this.modal.show = false;
		},
		showComment() {
			if (this.modal.comment) this.modal.showComment = true;
		},
		hideComment() {
			this.modal.showComment = false;
		},

		
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-center items-center">
		<div class="modal-wrap" v-if="modal.show || modal.showComment">
			<Modal
				@click.native="hideModal(), showComment()"
				v-if="modal.show"
				buttonText="Continuar"
				class="modal-base"
				:class="{ 'modal-active': modal.show, 'modal-out': modal.gone }"
			>
				<div class="text-white text-center">
					<span class="b yellow block text-xl">{{ modal.title }}</span>
					
					<span class="text-lg">{{ modal.message }}</span>
					<div v-if="modal.type == 'reaction'" class="flex">
						<img :src="getReactionImage(modal.reaction.image)" alt="" class="mx-auto w-20 animtae-smile">
					</div>
				</div>
			</Modal>
			<Modal
					buttonText="Continuar"
					class="modal-base"
					:class="{ 'modal-active': modal.showComment }"
					@click.native="hideComment()"
				>
					<div class="text-white text-center">
						<span class="b yellow block text-xl"
							>Comentarios adicionales:</span
						>
						<span class="block text-lg py-1">{{ modal.comment }}</span>
					</div>
			</Modal>
		</div>
		<div
			class="relative flex flex-col overlay bg-white mx-auto mt-auto mb-2"
		>
			<img
				class="image mb-auto mx-auto"
				:src="getImage('home_img.png')"
				alt="Trofeo"
			/>
			<p class="px-5 text-center text-3xl mb-3 leading-8">
				<span class="b">Reconoce </span>
				<i class="t">a tus </i>
				<span class="b blue">Compañeros</span>
			</p>
			<p class="px-5 text-center text-xl mb-5 overflow-y-auto">
				que crean
				<span class="b red">Momentos </span>
				<span class="b green-dark">Felices</span> porque son
				protagonistas de nuestra <span class="b blue">Cultura</span>.
			</p>
			<div class="mt-auto text-center">
				<secondary-button
					id="empezar"
					class="mb-3"
					text="Empezar"
					@click.native="begin"
				></secondary-button>
				<p class="px-5 text-center text-md">
					<span class="red">Incidencias:</span> <span class="block">Levanta un botón rojo en Zeus.</span>
				</p>
				<Footer class="mt-auto static"></Footer>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.overlay {
	height: 85vh;
	max-height: 640px;
}
.image {
	height: 45vh;
	margin-top: -25%;
}
.animtae-smile{
	animation: smileIn .4s forwards ease-in-out;
	animation-delay: 0.4s;
	opacity: 0;
}

@keyframes smileIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
@keyframes go-in {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0vw);
	}
}

@keyframes go-out {
	0% {
		transform: translateX(0vw);
	}
	100% {
		transform: translateX(-100vw);
	}
}
.modal-base {
	opacity: 0;
	transform: translateX(100vw);
	background: none;
}
.modal-active {
	opacity: 1;
	animation: go-in 0.4s;
	transform: translateX(0vw);
}
.modal-out {
	opacity: 1;
	animation: go-out 0.4s;
	transform: translateX(-100vw);
}

.modal-wrap {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
	z-index: 10000000;
}
</style>
